<template>
  <div>
    <FormView
      :formData="fromData"
      :formLabels="formLabels"
      :column="formColumn"
      :labelWidth="labelWidth"
    >
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
    </FormView>
    <el-tabs
      v-model="activeNameTab"
      class="demo-tabs"
      @tab-click="handleClickTab"
    >
      <el-tab-pane
        :label="`全部（${tabData.all}）`"
        :name="99999"
      ></el-tab-pane>
      <el-tab-pane :label="`未使用（${tabData.not}）`" :name="0"></el-tab-pane>
      <el-tab-pane :label="`已使用（${tabData.used}）`" :name="1"></el-tab-pane>
      <el-tab-pane
        :label="`已过期（${tabData.expire}）`"
        :name="2"
      ></el-tab-pane>
      <el-tab-pane
        :label="`已作废（${tabData.repeal}）`"
        :name="3"
      ></el-tab-pane>
    </el-tabs>
    <el-button
      type="primary"
      @click="handleClickVoids"
      style="margin-bottom: 20px"
    >
      批量废弃
    </el-button>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
      @changeCurrent="changeCurrent"
    >
      <template #nickName>
        <el-table-column label="用户信息" width="280" align="center">
          <template #default="scoped">
            <div class="desc">
              <img
                :src="
                  scoped.row.avatarUrl
                    ? scoped.row.avatarUrl
                    : require('../../assets/img/logo.jpg')
                "
              />
              <span>{{ scoped.row.nickName }}</span>
            </div>
          </template>
        </el-table-column>
      </template>
      <template #channel>
        <el-table-column label="领取方式" align="center">
          <template #default="scoped">
            {{ scoped.row.channel == 1 ? '用户领取' : '系统发放' }}
          </template>
        </el-table-column>
      </template>
      <template #state>
        <el-table-column label="使用状态" align="center">
          <template #default="scoped">
            {{ getState(scoped.row.state) }}
          </template>
        </el-table-column>
      </template>
      <template #action>
        <el-table-column label="操作" align="center" width="200">
          <template #default="scoped">
            <el-button
              type="primary"
              size="small"
              @click="handleClickLook(scoped.row)"
              v-if="scoped.row.orderId"
            >
              查看订单
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="handleClickVoid(scoped.row)"
              v-if="scoped.row.state == 0"
            >
              作废
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
  </div>
</template>

<script setup>
import { reactive, onMounted, h, toRefs, ref } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  hisQueryCriteria,
  couponHisRepeal
} from '@/api/cardManagement/platform'
import { useRouter } from 'vue-router'
const router = useRouter()
const initData = reactive({
  formLabels: [
    {
      label: 'userInfo',
      title: '用户信息',
      placeholder: '请输入用户编号/昵称'
    },
    {
      label: 'couponName',
      title: '优惠券名称',
      placeholder: '请输入用户编号/昵称'
    },
    {
      label: 'getType',
      title: '领取方式',
      placeholder: '请选择',
      type: 'options',
      options: [
        {
          label: '用户领取',
          value: 1
        },
        {
          label: '系统发放',
          value: 2
        }
      ]
    }
  ],
  fromData: {
    // 表单数据
    userInfo: '',
    couponName: '',
    getType: ''
  },
  formColumn: 4,
  labelWidth: '90px',
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    { selection: true },
    {
      index: true,
      indexMethod(index) {
        return index + 1
      },
      width: 70,
      label: '编号'
    },
    { slot: 'nickName' },
    { prop: 'couponName', label: '优惠券名称' },
    { slot: 'channel' },
    { prop: 'receiveTime', label: '领取时间' },
    { prop: 'invalidTime', label: '过期时间' },
    { slot: 'state' },
    { prop: 'useTime', label: '使用时间' },
    { slot: 'action' }
  ],
  labelVisible: false,
  froms: {},
  activeNameTab: 99999,
  tabData: {
    all: 0,
    conduct: 0,
    end: 0,
    not: 0
  },
  changeCurrentData: []
})

const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth,
  activeNameTab,
  tabData,
  changeCurrentData
} = toRefs(initData)

onMounted(() => {
  getDataList()
})

// 切换tab
const handleClickTab = val => {
  activeNameTab.value = val.props.name
  search()
}

// 领取方式
const getState = val => {
  let vals = ''
  switch (val) {
    case 0:
      vals = '未使用'
      break
    case 1:
      vals = '已使用'
      break
    case 2:
      vals = '已过期'
      break
    case 3:
      vals = '已作废'
      break
  }
  return vals
}

// 重置
const reset = () => {
  fromData.value = {}
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (fromData.value.userInfo !== '') {
    data.userInfo = fromData.value.userInfo
  }
  if (fromData.value.couponName !== '') {
    data.couponName = fromData.value.couponName
  }
  if (fromData.value.getType !== '') {
    data.getType = fromData.value.getType
  }
  if (activeNameTab.value != 99999) {
    data.state = activeNameTab.value
  }
  hisQueryCriteria(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
      tabData.value = res.data.extend
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}

// 选中
const changeCurrent = val => {
  changeCurrentData.value = val.filter(item => item.state == 0)
}

// 查看订单
const handleClickLook = row => {
  router.push({
    path: '/orderDetails',
    query: {
      id: row.orderId
    }
  })
}

// 作废
const handleClickVoids = () => {
  if (changeCurrentData.value.length == 0) {
    ElMessage.warning('请选择批量废弃数据记录')
    return false
  }
  ElMessageBox.confirm(`是否确认批量作废（该操作不可逆）？`, '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      const data = changeCurrentData.value.map(item => item.id)
      couponHisRepeal(data).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

const handleClickVoid = row => {
  ElMessageBox.confirm(`是否确认作废（该操作不可逆）？`, '提示：', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(() => {
      couponHisRepeal([row.id]).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}
</script>
<style lang="scss" scoped>
.desc {
  display: flex;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
    margin-right: 5px;
  }

  span {
    width: 300px;
    text-align: left;
  }
}
</style>
